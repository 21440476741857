// Azerbaijani
export default{
    form: {
        startSurveyButton: "Başlayaq",
        nextButton: "Növbəti",
        submitAnswerButton: "Cavabı göndər",
        continueButton: "Davam et",
        finishButton: "Bitir",
        headerPercentageCompletion: 'tamamlandı',
        headerQuestionsCompleted: 'sual',
        headerDefaultErrorMessage: 'Oops. Bir şey səhv getdi.',
        brandingPoweredBy: 'Tərəfindən təmin olunur',
        brandingPromoWhySurvey: 'Niyə sorğu edəsiniz ki, <b>{product}</b> edə bilərsiniz?',
        brandingPromoGetStarted: 'Bu gün başlayın!',
        submissionPending: "Voiceform göndərilir. Zəhmət olmasa, brauzer pəncərəsini bağlamayın.",
        submissionCanCloseWindow: "Voiceform uğurla göndərildi. İndi pəncərəni bağlaya bilərsiniz 👍",
        mediaAttachmentOverlayCloseButton: "Bağla",
        expiredFormHeader: "Gəldiyiniz üçün təşəkkürlər!",
        expiredFormMessage: "Görünür, bu sorğunun müddəti bitib. Zəhmət olmasa, voiceform yaradıcısı ilə əlaqə saxlayın.",
        notPublishedFormHeader: "Voiceform dərc olunmayıb!",
        notPublishedFormMessage: "Bu sorğunun dərc olunmadığı görünür. Zəhmət olmasa, voiceform yaradıcısı ilə əlaqə saxlayın.",
        notPublishedFormRetryButton: "Yenidən cəhd edin",
        errorFormHeader: "Oops!",
        errorFormMessage: "Nəyinsə səhv getdiyi görünür. Zəhmət olmasa, sonra yenidən cəhd edin.",
        errorFormRetryButton: "Yenidən cəhd edin",
        emptyFormHeader: "Gəldiyiniz üçün təşəkkürlər!",
        emptyFormMessage: "Oops! Görünür, bu form hazırda boşdur və doldurmaq üçün suallar və ya sahələr yoxdur. Yaranan narahatçılığa görə üzr istəyirik. Xahiş edirik bu problemi həll etmək üçün forma yaradıcısı ilə əlaqə saxlayın. Anlayışınız və əməkdaşlığınız üçün təşəkkür edirik!",

        restoreFormHeader: "Görünür, tamamlanmamış formanız var!",
        restoreFormMessage: "Harada dayandığınız yerdən davam etmək istəyirsiniz?",
        restoreFormButton: "Formanı davam etdirin",
        restoreFormButtonNew: "Yenidən başla",

        audioPermissionHeader: "Səs ilə cavab vermək istəyirsiniz?",
        audioPermissionMessage: "Danışmaq cavab verməyi sürətli və asan edəcək.",
        audioPermissionAcceptButton: "Bəli, istəyirəm",
        audioPermissionDenyButton: "Xeyr, təşəkkürlər",
        audioPermissionErrorHeader: "Mikrofon mövcud deyil",
        audioPermissionErrorMessage: "Mikrofona giriş imkanı üçün brauzer icazələrini yoxlayın.",
        audioPermissionErrorButton: "Tamam",
        audioRecorderFailedUpload: "Qeydi yükləmək alınmadı",
        silenceDetectorError: "Sizi eşitmirik. Zəhmət olmasa, mikrofon ayarlarını yoxlayın. Məsləhət: Xarici mikrofon ola bilər.",
        audioRecorderButtonRecord: "Qeydi başla",
        audioRecorderButtonRecordMore: "Daha çox qeyd et",
        voiceResponseSelectionMessage: "Necə cavab vermək istədiyinizi seçin...",
        voiceResponseSelectionOrDivider: "və ya",
        voiceResponseTextInputPlaceholder: "Cavabı yazın",
        voiceResponseTextInputCharacterCounterMinMessage: "Zəhmət olmasa, ən azı {min} simvol daxil edin",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} simvol (ən azı {min} simvol)",
        voiceResponseTextInputCharacterValidationMessage: "{count} simvol (ən azı {min} simvol)",

        // checkbox
        checkboxValidationTooFew: "Ən azı {min} seçim etməlisiniz",
        checkboxValidationTooMany: "Ən çox {max} seçim etməlisiniz",
        checkboxNoneOfTheAboveOption: "Heç biri",
        checkboxInvalidAnswer: "Yanlış cavab.",

        // datepicker
        datePickerPlaceholder: "Tarix seçin",

        // dropdown
        // email
        emailLabel: "E-poçt",
        emailInvalid:"Yanlış e-poçt.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Video qeyd et",
        fileUploadPluginScreenCast: "Ekran yazısı",
        fileUploadVideoImportFilesDefault: 'Video qeyd edin və ya yükləyin:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Video qeyd etmə seçimini seçin:',
        fileUploadVideoImportFilesNoCamera: 'Video qeyd etmək üçün düyməni basın',
        fileUploadVideoImportFilesNoScreenCast: 'Video yazmaq üçün düyməni basın',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Video yazmaq üçün mövcud seçimlər yoxdur',

        // matrix
        matrixValidationMessage: "Cavab doğrudur.",
        matrixRow: "Sətir",
        matrixColumn: "Sütun",
        matrixRowRequired: "{rowTitle} sətri tələb olunur.",
        matrixRadioGroup: "Radio qrupu",
        matrixCheckboxGroup: "Checkbox qrupu",
        matrixGroupRequired: "{rowTitle} sətri üçün. {type} {groupTitle} tələb olunur.",
        matrixColumnRequired: "{rowTitle} sətri üçün. {columnTitle} sütunu tələb olunur.",
        matrixColumnInvalid: "{rowTitle} sətri üçün. {columnTitle} sütunu yanlışdır.",
        matrixRequired: "Tələb olunur.",
        matrixNumericMustBeNumber: "Rəqəm olmalıdır.",
        matrixNumericMustBeGreaterThenMin: "{min} dəyərindən böyük olmalıdır.",
        matrixNumericMustBeLessThenMax: "{max} dəyərindən az olmalıdır.",
        matrixNumericMustBeInteger: "Tam rəqəm olmalıdır.",
        matrixNumericInvalidNumber: "Yanlış rəqəm.",

        // name
        nameLabel: "Ad",
        nameInvalid: "Ad və soyad daxil edin.",

        // nps
        npsNotLikely: "Heç ehtimal yoxdur",
        npsExtremelyLikely: "Çox ehtimal olunur",

        // numeric input
        numericInputRequired: "Tələb olunur.",
        numericInputMustBeNumber: "Rəqəm olmalıdır.",
        numericInputMustBeGreaterThenMin: "{min} dəyərindən böyük olmalıdır.",
        numericInputMustBeLessThenMax: "{max} dəyərindən az olmalıdır.",
        numericInputMustBeInteger: "Tam rəqəm olmalıdır.",
        numericInputInvalidNumber: "Yanlış rəqəm.",
        numericInputPlaceholder: "Rəqəmi daxil edin",

        // phone
        phoneInvalid: "Telefon nömrəsi yanlışdır.",
        phoneCountrySelectorLabel: 'Ölkə kodu',
        phoneCountrySelectorError: 'Ölkə seçin',
        phoneNumberLabel: 'Telefon nömrəsi',
        phoneExample: 'Məsələn:',

        // boolean
        booleanYesLabel: "Bəli",
        booleanNoLabel: "Xeyr",

        //questionStep
        questionStepAudioQuestionLabel: "Səsli sual",

        // errors
        invalidPhoneNumber: "{phone} yanlış telefon nömrəsidir.",
        invalidEmail: "{email} yanlış e-poçt ünvanıdır.",
        questionIsRequired: "Sual tələb olunur.",
        answerIsNotValid: "Cavab yanlışdır.",
        unfinishedProbingDialogError: "Zəhmət olmasa, dialoqu tamamlayın.",
        cannotResumePartialResponse: "Qismən cavabı davam etdirmək mümkün deyil.",
        failedToSubmitForm: "Formu göndərmək alınmadı. Zəhmət olmasa, internet bağlantınızı yoxlayın və yenidən cəhd edin."
    }
}
